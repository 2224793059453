import React, { Component } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import { Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'

import FlcButton from '../atoms/FlcButton'

// TODO
// - Convert to functional component?
// - Refactor for mui4 and hooks?

const SUBJECT_OPTIONS = [
    {
        value: 'General Enquiry',
        label: 'General Enquiry',
    },
    {
        value: 'Building a new home',
        label: 'Building a new home',
    },
    {
        value: 'Home renovations',
        label: 'Home renovations',
    },
]

const styles = (theme) => ({
    form: {},
    fieldContainer: {
        position: 'relative',
        paddingBottom: 24,
        marginBottom: 0,
        transition: 'margin 0.3s ease-in-out',
        '&.hasError': {
            marginBottom: 16,
        },
    },
    fieldError: {
        opacity: 0,
        position: 'absolute',
        bottom: 0,
        left: 0,
        color: theme.palette.error.main,
        fontSize: theme.typography.pxToRem(14),
        transition: 'opacity 0.3s ease-in-out',
        '.hasError &': {
            opacity: 1,
        },
    },
    SubmitButtonWrapper: {
        position: 'relative',
        display: 'inline-block',
    },
    submitButton: {
        margin: 0,
    },
    submitButtonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
})

export class ContactForm extends Component {
    constructor(props) {
        super(props)

        this.initialFormFieldsState = {
            name: '',
            email: '',
            phone: '',
            subject: SUBJECT_OPTIONS[0].value,
            message: '',
        }

        this.state = {
            fields: this.initialFormFieldsState,
            errors: {},
            isSubmitting: false,
        }
    }

    fieldHasError(fieldName) {
        return !!this.state.errors[fieldName]
    }

    isValidEmail = (value) => {
        // Regex from from https://emailregex.com/
        // eslint-disable-next-line
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/.test(
            value
        )
    }

    isValidPhone = (value) => {
        // Regex from from https://www.regextester.com/99524
        // eslint-disable-next-line
        return /([(+]*[0-9]+[()+. -]*)/.test(value)
    }

    encodeFormData = (data) => {
        return Object.keys(data)
            .map(
                (key) =>
                    encodeURIComponent(key) +
                    '=' +
                    encodeURIComponent(data[key])
            )
            .join('&')
    }

    resetForm = () => {
        this.setState({ fields: this.initialFormFieldsState })
    }

    validateForm = () => {
        const { fields } = this.state
        const requiredFields = ['name', 'email', 'subject', 'message']
        let errors = {}
        let isValid = true

        // Check for valid email
        if (!this.isValidEmail(fields.email)) {
            isValid = false
            errors.email = 'Please enter a valid email address'
        }

        // check for valid phone number
        if (fields.phone !== '' && !this.isValidPhone(fields.phone)) {
            isValid = false
            errors.phone = 'Please enter a valid phone number'
        }

        // Check for empty required fields
        requiredFields.forEach((requiredField) => {
            if (!fields[requiredField]) {
                isValid = false
                errors[requiredField] = `Please enter your ${requiredField}`
            }
        })

        this.setState({ errors })

        return isValid
    }

    handleChange = (fieldName) => (event) => {
        // Set new field value
        const fields = {
            ...this.state.fields,
            [fieldName]: event.target.value,
        }

        // Remove existing field validation errors
        const errors = {
            ...this.state.errors,
        }

        if (errors[fieldName]) {
            delete errors[fieldName]
        }

        this.setState({ fields, errors })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        const formIsValid = this.validateForm()

        if (formIsValid) {
            this.setState({ isSubmitting: true })
            axios({
                method: 'POST',
                url: '/contact/',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                data: this.encodeFormData({
                    'form-name': 'contact',
                    ...this.state.fields,
                }),
            })
                .then(() => {
                    this.setState({ isSubmitting: false })
                    this.resetForm()
                    this.props.onSuccess && this.props.onSuccess()
                })
                .catch((error) => {
                    this.setState({ isSubmitting: false })
                    console.warn(error)
                    this.props.onError && this.props.onError()
                })
        }
    }

    render() {
        const { classes, className } = this.props
        const { errors, fields, isSubmitting } = this.state

        return (
            <form
                className={classNames(classes.form, className)}
                name="contact"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                noValidate
                autoComplete="off"
            >
                <input type="hidden" name="form-name" value="contact" />
                <input type="hidden" name="bot-field" />
                <div
                    className={classNames(
                        classes.fieldContainer,
                        this.fieldHasError('name') && 'hasError'
                    )}
                >
                    <TextField
                        id="name"
                        name="name"
                        type="text"
                        label="Name"
                        className={classes.textField}
                        value={fields.name}
                        onChange={this.handleChange('name')}
                        error={this.fieldHasError('name')}
                        fullWidth
                    />
                    <Typography className={classes.fieldError}>
                        {errors.name}
                    </Typography>
                </div>
                <div
                    className={classNames(
                        classes.fieldContainer,
                        this.fieldHasError('email') && 'hasError'
                    )}
                >
                    <TextField
                        id="email"
                        name="email"
                        type="email"
                        label="Email Address"
                        className={classes.textField}
                        value={fields.email}
                        onChange={this.handleChange('email')}
                        error={this.fieldHasError('email')}
                        fullWidth
                    />
                    <Typography className={classes.fieldError}>
                        {errors.email}
                    </Typography>
                </div>
                <div
                    className={classNames(
                        classes.fieldContainer,
                        this.fieldHasError('phone') && 'hasError'
                    )}
                >
                    <TextField
                        id="phone"
                        name="phone"
                        type="tel"
                        label="Phone Number"
                        className={classes.textField}
                        value={fields.phone}
                        onChange={this.handleChange('phone')}
                        error={this.fieldHasError('phone')}
                        fullWidth
                    />
                    <Typography className={classes.fieldError}>
                        {errors.phone}
                    </Typography>
                </div>
                <div
                    className={classNames(
                        classes.fieldContainer,
                        this.fieldHasError('subject') && 'hasError'
                    )}
                >
                    <TextField
                        select
                        id="subject"
                        name="subject"
                        label="What can we help you with?"
                        className={classes.textField}
                        value={fields.subject}
                        onChange={this.handleChange('subject')}
                        fullWidth
                    >
                        {SUBJECT_OPTIONS.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div
                    className={classNames(
                        classes.fieldContainer,
                        this.fieldHasError('message') && 'hasError'
                    )}
                >
                    <TextField
                        id="message"
                        name="message"
                        label="How can we help you?"
                        multiline
                        rows="12"
                        className={classes.textField}
                        value={fields.message}
                        onChange={this.handleChange('message')}
                        error={this.fieldHasError('message')}
                        fullWidth
                    />
                    <Typography className={classes.fieldError}>
                        {errors.message}
                    </Typography>
                </div>
                <div className={classes.SubmitButtonWrapper}>
                    <FlcButton
                        text={'Send'}
                        onClick={this.handleSubmit}
                        className={classes.submitButton}
                        disabled={isSubmitting}
                    />
                    {isSubmitting && (
                        <CircularProgress
                            size={24}
                            className={classes.submitButtonProgress}
                        />
                    )}
                </div>
            </form>
        )
    }
}

ContactForm.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
}

export default withStyles(styles)(ContactForm)
