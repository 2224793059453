import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'

import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import SnackbarContent from '@material-ui/core/SnackbarContent'

import InfoIcon from '@material-ui/icons/Info'
import ErrorIcon from '@material-ui/icons/Error'
import CloseIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import green from '@material-ui/core/colors/green'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: theme.palette.primary.dark,
    border: `2px solid ${green[600]}`,
  },
  error: {
    backgroundColor: theme.palette.primary.dark,
    border: `2px solid ${theme.palette.error.main}`,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
    border: `2px solid ${theme.palette.primary.light}`,
  },
  warning: {
    backgroundColor: theme.palette.primary.dark,
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  icon: {
    fontSize: 24,
    color: theme.palette.text.primary,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(2),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(16),
  },
}))

function Toast({
  className,
  open,
  message,
  onClose,
  variant,
  anchorOrigin,
  ...other
}) {
  const classes = useStyles()
  const Icon = variantIcon[variant]

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={5000}
      onClose={onClose}
    >
      <SnackbarContent
        className={classNames(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
    </Snackbar>
  )
}

Toast.defaultProps = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
}

Toast.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  message: PropTypes.node,
  onClose: PropTypes.func,
  anchorOrigin: PropTypes.object,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
}

export default Toast
