import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'

import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/PhoneIphone'

import SEO from '../components/seo'
import Layout from '../components/layout'
import Toast from '../components/molecules/Toast'
import PageHeader from '../components/organisms/PageHeader'
import ContentSection, {
    contentSectionWidths,
} from '../components/atoms/ContentSection'
import ExternalLink from '../components/atoms/ExternalLink'
import ContactForm from '../components/organisms/ContactForm'

import { trackEvent } from '../utils/analytics'

const useStyles = makeStyles((theme) => ({
    phoneLink: {
        marginTop: `${theme.spacing(3)}px`,
        fontSize: theme.typography.pxToRem(20),

        '& svg': {
            position: 'relative',
            top: '2px',
            marginRight: `${theme.spacing(1)}px`,
        },
    },
    emailMessage: {
        display: 'flex',

        '& svg': {
            position: 'relative',
            top: '1px',
            marginRight: `${theme.spacing(1)}px`,
        },
    },
    form: {
        marginTop: theme.spacing(6),
    },
}))

function ContactPage({ data }) {
    const classes = useStyles()
    const [showFormSuccessToast, setShowFormSuccessToast] = useState(false)
    const [showFormErrorToast, setShowFormErrorToast] = useState(false)

    const {
        headline,
        bannerBlurb,
        seoTitle,
        seoDescription,
    } = data.contentfulContactPage

    function handleFormSuccess() {
        trackEvent('ContactForm', 'Submit', `Success`)

        setShowFormSuccessToast(true)
        setShowFormErrorToast(false)
    }

    function handleFormError() {
        trackEvent('ContactForm', 'Submit', `Error`)

        setShowFormErrorToast(true)
        setShowFormSuccessToast(false)
    }

    return (
        <Layout>
            <SEO title={seoTitle} description={seoDescription} />
            <PageHeader
                headline={headline}
                blurb={bannerBlurb.childMarkdownRemark.html}
            />
            <ContentSection width={contentSectionWidths.small}>
                <Typography variant="body1" component={'div'}>
                    <p>
                        <ExternalLink
                            className={classes.phoneLink}
                            link={'tel:021 883 985'}
                        >
                            <PhoneIcon />
                            021 883 985
                        </ExternalLink>
                    </p>
                    <p className={classes.emailMessage}>
                        <EmailIcon />
                        Use the form below to send us an email enquiry.
                    </p>
                </Typography>
                <ContactForm
                    onSuccess={handleFormSuccess}
                    onError={handleFormError}
                    className={classes.form}
                />
            </ContentSection>
            <Toast
                open={showFormSuccessToast}
                onClose={() => setShowFormSuccessToast(false)}
                variant="success"
                message={
                    <p>
                        Your message has been sent, we will be in touch soon.
                        <br />
                        Thank you.
                    </p>
                }
            />
            <Toast
                open={showFormErrorToast}
                onClose={() => setShowFormErrorToast(false)}
                variant="error"
                message={
                    <p>
                        Sorry, your message could not be sent.
                        <br />
                        Please try again later or give us a call.
                    </p>
                }
            />
        </Layout>
    )
}

export default ContactPage

export const query = graphql`
    query ContactPageQuery {
        site {
            siteMetadata {
                title
            }
        }
        contentfulContactPage(slug: { eq: "contact" }) {
            slug
            title
            headline
            bannerBlurb {
                childMarkdownRemark {
                    html
                }
            }
            seoTitle
            seoDescription
        }
    }
`
